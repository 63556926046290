button.euiSuperSelectControl.euiFormControlLayout--1icons.euiSuperSelectControl--compressed.euiSuperSelect--isOpen__button {
  background-image: none;
}

.myCustomSelectable .euiSelectableListItem-isFocused,
.myCustomSelectable .euiSelectableListItem-isSelected {
  background-color: red; /* Replace with your primary color */
}

.div {
  background: linear-gradient(
    45deg,
    #2800a0,
    #310d9d,
    #3a1d90,
    #442a90,
    #4b368c,
    #54418d,
    #5a4a8c,
    #64568e,
    #6a608a,
    #706889,
    #79738b,
    #817d8e,
    #8b8991,
    #8e8d8e
  );
}

.div {
  background: linear-gradient(
    45deg,
    #2800a0,
    #3a199e,
    #462e90,
    #5c4995,
    #6b608d,
    #7e788f,
    #8c8b8e,
    #7e788f,
    #6b608d,
    #5c4995,
    #462e90,
    #3a199e,
    #2800a0
  );
}
