@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: black;
  color: #333;
}

html,
body,
#root {
  height: 100vh;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
